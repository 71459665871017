import React, { useState } from "react";
import { Alert, Button, IconButton, TextField, Avatar } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { User } from "./User";
import { DataTable } from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Column } from "../../types/Column";
import EditModal from "../../components/EditModal";
import BanUserModal from "../../components/DeleteDialog";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addNewUser, fetchUsers, removeUser, updateUser } from "../../api";
import AddModal from "../../components/AddModal";
import { AxiosError } from "axios";

const UserPage = () => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isBanModalOpen, setBanModalOpen] = useState(false);
  const [currentUserData, setCurrentUserData] = useState<User | null>(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [tokens, setTokens] = useState<string[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);

  const handleEditClick = (userData: User) => {
    setCurrentUserData(userData);
    setEditModalOpen(true);
  };

  const handleBanClick = (userData: User) => {
    setCurrentUserData(userData);
    setBanModalOpen(true);
  };

  // Handler for when the modal is closed without saving
  const handleClose = () => {
    setEditModalOpen(false);
    setBanModalOpen(false);
    setAddModalOpen(false);
  };

  // Handler for when the modal save action is performed
  const handleSave = (updatedUserData: User) => {
    changeUser.mutate(updatedUserData);
    setEditModalOpen(false);
  };

  const handleConfirmBan = () => {
    if (!currentUserData) return;
    deleteUser.mutate({ ext_id: currentUserData.ext_id });
    setBanModalOpen(false);
  };

  const handleAdd = (updateddata: User) => {
    addUser.mutate(updateddata);
    handleClose();
  };

  const columns: Column<User>[] = [
    {
      label: "id",
      render: (user) => <>{user.id}</>,
    },
    { label: "Аватар", render: (user) => <Avatar src={user.avatar} /> },
    {
      label: "Никнейм",
      render: (user) => <>{user.nickname}</>,
    },
    {
      label: "Телефон",
      render: (user) => <>{user.phone}</>,
    },
    {
      label: "Действия",
      render: (user) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditClick(user)}
            key={user.id}
          >
            <EditIcon />
          </IconButton>
          <IconButton aria-label="block" onClick={() => handleBanClick(user)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const queryClient = useQueryClient();
  const { data, error, isLoading } = useQuery({
    queryKey: ["users", rowsPerPage, nextPageToken, search],
    queryFn: () => fetchUsers(rowsPerPage, nextPageToken, search),
  });

  const addUser = useMutation({
    mutationFn: addNewUser,
    mutationKey: ["users"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["users"] }),
  });

  const deleteUser = useMutation({
    mutationFn: removeUser,
    mutationKey: ["users"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["users"] }),
  });

  const changeUser = useMutation({
    mutationFn: updateUser,
    mutationKey: ["users"],
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["users"] }),
  });

  const mutationError = (
    ((changeUser.error || addUser.error) as AxiosError)?.response?.data as any
  )?.error;

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "10px" }}>
      <h1>Пользователи</h1>
      <div style={{ marginBottom: "20px" }}>
        <TextField
          id="search-user"
          label="Поиск"
          variant="outlined"
          size="small"
          style={{ marginRight: "8px" }}
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => setSearch(searchText)}>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
        <Button onClick={() => setAddModalOpen(true)}>+Добавить</Button>
      </div>
      {mutationError && (
        <Alert severity="error" style={{ marginBottom: "20px" }}>
          {mutationError}
        </Alert>
      )}
      {data && (
        <DataTable
          rowsPerPageOptions={[20, 50, 100]}
          columns={columns}
          data={data.data.users}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={(event) => {
            setRowsPerPage(+event.target.value);
          }}
          page={page}
          handleChangePage={(event, newPage) => {
            setPage(newPage);
            if (newPage > page) {
              setNextPageToken(data.next_page_token);
              setTokens((prev) => [...prev, data.next_page_token]);
            } else {
              setNextPageToken(tokens[newPage - 1]);
            }
          }}
          count={data?.data.num_elements}
        />
      )}{" "}
      <AddModal
        open={isAddModalOpen}
        onClose={handleClose}
        title="Добавить пользователя"
        defaultState={{
          nickname: "",
          about: "",
          phone: "",
          email: "",
          is_18: false,
          is_open: false,
          subscribe_newsletter: false,
          expertise: false,
          id: 0,
          ext_id: "",
        }}
        columns={[
          { label: "Никнейм", key: "nickname", editable: true },
          { label: "Описание", key: "about", editable: true },
          { label: "Телефон", key: "phone", editable: true },
          { label: "Почта", key: "email", editable: true },
          {
            label: "18+",
            key: "is_18",
            editable: true,
            type: "switch",
          },
          {
            label: "Закрытый профиль",
            key: "is_open",
            editable: true,
            type: "switch",
          },
          {
            label: "Подписан на новости",
            key: "subscribe_newsletter",
            type: "switch",
            editable: true,
          },
          {
            label: "Экспертность",
            key: "expertise",
            editable: true,
            type: "switch",
          },
        ]}
        onSave={handleAdd}
      />
      {currentUserData && (
        <EditModal
          open={isEditModalOpen}
          onClose={handleClose}
          title="Редактирование пользователя"
          data={currentUserData}
          columns={[
            { label: "Никнейм", key: "nickname", editable: true },
            { label: "Описание", key: "about", editable: true },
            { label: "Телефон", key: "phone", editable: true },
            { label: "Почта", key: "email", editable: true },
            {
              label: "18+",
              key: "is_18",
              editable: true,
              type: "switch",
            },
            {
              label: "Закрытый профиль",
              key: "is_open",
              editable: true,
              type: "switch",
            },
            {
              label: "Подписан на новости",
              key: "subscribe_newsletter",
              type: "switch",
              editable: true,
            },
            {
              label: "Экспертность",
              key: "expertise",
              editable: true,
              type: "switch",
            },
          ]}
          onSave={handleSave}
        />
      )}
      <BanUserModal
        open={isBanModalOpen}
        onClose={handleClose}
        onConfirm={handleConfirmBan}
        title="Удаление пользователя"
        text={`Вы точно хотите удалить пользователя
        ${currentUserData?.nickname}?`}
        deleteButton="Удалить"
      />
    </div>
  );
};

export default UserPage;
